//profile.tsx

import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {
    addPost,
    getCreatorByUsername, getFormattedAddress,
    getPostsByUsername, setProfilePicture,
    updateCreator,
    updatePost, updateReactionCount,
    uploadImage
} from '../api'; // No separate uploadImage import
import {useAuth} from "../auth/AuthProvider";
import {ToastContainer, toast} from 'react-toastify';
import {useJsApiLoader, Autocomplete, Libraries} from '@react-google-maps/api'; // Import Libraries
import './Profile.css';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"; // Masonry import
import {motion, AnimatePresence} from 'framer-motion';
import ClipLoader from 'react-spinners/ClipLoader';  // Add spinner import
import soltLogo from '../assets/logo.png';
import {HelmetProvider} from 'react-helmet-async';
import ReactGA from "react-ga4";
import posthog from 'posthog-js';
import normalReactionIcon from '../assets/normal-reaction-icon.png'
import likedReactionIcon from '../assets/liked-reaction-icon.png'
import settingsIcon from '../assets/setting-lines-icon.png';
import restaurantPlaceIcon from "../assets/restaurant-place-icon.png";

// Import the date-fns function for formatting relative dates
import {formatDistanceToNow} from 'date-fns';
import BottomModal from "../components/BottomModal";
import redirectIcon from "../assets/redirect-icon.png";
import {extractLocationInfo} from "../helpers/helpers";
import starIcon from "../assets/star-icon.png";

const libraries: Libraries = ['places']; // Correct type for Google Maps libraries
const GOOGLE_MAPS_API_KEY = 'AIzaSyAx2tQrhPw3tAhUTdO1f4skW9zPpSZmyn4';
ReactGA.initialize("G-TLM63YPZGE");

declare const plausible: (eventName: string, options?: { props: Record<string, string> }) => void; // Plausible typing

interface Post {
    _id: string | number;
    images: string[];
    title: string;
    description: string;
    location?: string;
    google_place_id?: string;
    location_english_formatted?: string;
    updated?: string;
    created?: string;
    reactionCount: number; // Now we only track the count of reactions
    ratings?: {
        dishRating?: number; // New field for taste rating
        wouldEatAgain?: boolean; // New field for "Would you eat it again?"
    };
}

interface ProfileInfo {
    name?: string; // Optional with string | undefined
    username?: string;
    bio?: string;
    profileImage?: string;
    postsTitle?: string;
}

posthog.init('phc_Pz5SLU7O6UiCM3I5oabytoGoVTAkiMdROvO34hzDmiI',
    {
        api_host: 'https://eu.i.posthog.com',
        person_profiles: 'always' // or 'always' to create profiles for anonymous users as well
    }
)

const Profile: React.FC = () => {
    const {username} = useParams<{ username: string }>();
    console.log("Username:", username); // Check if the username is being captured
    const {currentUser, logOut} = useAuth();
    const isAuthenticated = !!currentUser;
    // New state to track if the user is viewing their own profile
    const [isOwnProfile, setIsOwnProfile] = useState<boolean>(false);

    // console.log(currentUser);

    const [posts, setPosts] = useState<Post[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedPost, setSelectedPost] = useState<Post | null>(null);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isViewing, setIsViewing] = useState<boolean>(false);
    const [profileInfo, setProfileInfo] = useState<ProfileInfo>({
        name: '',
        bio: '',
        profileImage: '',
        postsTitle: '',
    });

    // State for editing profile info in the modal
    const [profileEditInfo, setProfileEditInfo] = useState<ProfileInfo>({
        name: '',
        bio: '',
        profileImage: '',
        postsTitle: '',
    });

    const [newPost, setNewPost] = useState<Post>({
        _id: 0,
        images: [],
        title: '',
        description: '',
        location: '',
        reactionCount: 0,
        ratings: {
            dishRating: undefined, // Set as undefined initially
            wouldEatAgain: undefined // Set as undefined initially
        }
    });

    const [isSheetOpen, setSheetOpen] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false); // New state for upload status

    const [fileToUpload, setFileToUpload] = useState<File | null>(null); // State to hold the file temporarily
    const [previewUrl, setPreviewUrl] = useState<string | null>(null); // State to store the preview URL

    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null); // State to manage autocomplete
    const [isProfileEditOpen, setProfileEditOpen] = useState<boolean>(false); // State for profile edit modal
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Loading state for animation
    const [animationClasses, setAnimationClasses] = useState<{ [key: string]: string }>({}); // Key-value store for postId and animation class
    const [creatorId, setCreatorId] = useState<string | null>(null);
    const [showDropdown, setShowDropdown] = useState(false); // Dropdown state
    const [showImageModal, setShowImageModal] = useState(false); // New state for showing enlarged image
    const dropdownRef = useRef<HTMLDivElement>(null); // Ref for dropdown
    const [formattedAddressPreview, setFormattedAddressPreview] = useState<string | null>(null);
    const [isFormattingAddress, setIsFormattingAddress] = useState(false);

    const pageTitle = `${profileInfo.name} | Solt`;
    const pageDescription = `Follow my Solt page to see what and where I eat.`;

    const handleLogout = async () => {
        await logOut();
        window.location.reload(); // Reload the page after logging out
    };

    useEffect(() => {
        if (isViewing) {
            // Simulate loading time (for example, animation duration)
            const timeout = setTimeout(() => {
                setIsLoading(false);
            }, 500); // 0.3s to match the animation duration

            return () => clearTimeout(timeout); // Cleanup timeout if component unmounts
        } else {
            setIsLoading(true); // Reset loading when the modal is closed
        }
    }, [isViewing]);

    useEffect(() => {
        if (isOverlayOpen) {
            // Add class to prevent background scrolling and interaction
            document.documentElement.classList.add('overlay-open');
        } else {
            // Remove class when the overlay is closed
            document.documentElement.classList.remove('overlay-open');
        }

        return () => {
            document.documentElement.classList.remove('overlay-open');
        };
    }, [isOverlayOpen]);


    // Fetch posts when component mounts or username changes
    useEffect(() => {
        // Fetch creator details by username
        const fetchCreator = async () => {
            setLoading(true);
            setError(null);

            try {
                if (username) {
                    const result = await getCreatorByUsername(username);

                    console.log('fetchCreator', result);

                    // console.log('creator: ', result);

                    if (result === 'not-found') {
                        console.error('Creator not found');
                        setError('Creator not found.');
                    } else if (result === 'error') {
                        console.error('Failed to fetch creator data');
                        setError('Failed to load creator details. Please try again.');
                    } else {
                        console.log('Creator data:', result);

                        setCreatorId(result._id);

                        setProfileInfo({
                            name: result.name,
                            bio: result.bio || '',
                            profileImage: result.profileImage || './creator_profile_image_placeholder.png',
                            postsTitle: result.postsTitle || ''
                        });

                        // Also set the data in the profileEditInfo for editing in the modal
                        setProfileEditInfo({
                            name: result.name,
                            bio: result.bio || '',
                            profileImage: result.profileImage || './creator_profile_image_placeholder.png',
                            postsTitle: result.postsTitle || ''
                        });

                        if (result.firebaseUID === currentUser?.uid) {
                            setIsOwnProfile(true);
                        } else {
                            setIsOwnProfile(false);
                        }
                    }
                }
            } catch (error) {
                console.error('An error occurred while fetching creator details:', error);
                setError('An error occurred. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        const fetchPosts = async () => {
            setLoading(true);
            setError(null);

            const result = await getPostsByUsername(username || '');

            if (result === 'error') {
                setError('Failed to load posts. Please try again.');
            } else if (result === 'not-found') {
                setError('No posts found for this user.');
            } else if (result === 'missing-username') {
                setError('Username is required to fetch posts.');
            } else {

                // Update posts with reactionCount defaulting to 0 if it's not defined
                const updatedPosts = result.map(post => ({
                    ...post,
                    reactionCount: post.reactionCount || 0, // Default to 0 if reactionCount is missing
                }));
                setPosts(updatedPosts);
                console.log(updatedPosts);
            }

            setLoading(false);
        };

        if (username) {
            fetchCreator();
            fetchPosts(); // Trigger the API call
        }
    }, [username]);

    const handlePostSubmit = async () => {
        setUploading(true);
        let imageUrl: string | null = null;

        if (fileToUpload && currentUser) {
            try {
                const creatorId = currentUser.uid; // Assuming `creatorId` is available on the current user
                const uploadResult = await uploadImage(fileToUpload, creatorId); // Upload the image

                if (uploadResult === 'invalid-file') {
                    setUploading(false);
                    toast.error('Invalid file format. Please upload a valid image.');
                    return; // Stop submission if image upload fails
                } else if (uploadResult === 'upload-error') {
                    setUploading(false);
                    toast.error('Image upload failed. Please try again.');
                    return; // Stop submission if image upload fails
                } else {
                    imageUrl = uploadResult; // Successful upload returns URL
                }
            } catch (error) {
                console.error('Image upload failed:', error);
                toast.error('Failed to upload the image.');
                setUploading(false);
                return; // Stop submission if image upload fails
            }
        } else if (newPost.images.length > 0) {
            imageUrl = newPost.images[0]; // Use existing URL if no new file is uploaded
        }

        if (!imageUrl) {
            setUploading(false);
            toast.error('Image is required');
            return; // Stop if no image URL is available
        }

        // Conditionally add ratings to postToSubmit if they have values
        const ratings: { dishRating?: number; wouldEatAgain?: boolean } = {};
        if (newPost.ratings?.dishRating) ratings.dishRating = newPost.ratings.dishRating;
        if (newPost.ratings?.wouldEatAgain !== undefined) ratings.wouldEatAgain = newPost.ratings.wouldEatAgain;

        console.log('ratings: ', ratings);
        if (ratings && (!ratings.dishRating || ratings.wouldEatAgain === undefined)) {
            setUploading(false);
            toast.error('Please rate the dish');
            return; // Stop if no image URL is available
        }

        const postToSubmit = {
            ...newPost,
            images: [imageUrl],
            location: newPost.location || '',
            reactionCount: newPost.reactionCount || 0,
            ...(Object.keys(ratings).length > 0 && { ratings }), // Only add ratings if they contain values
            ...(newPost.location_english_formatted && {location_english_formatted: newPost.location_english_formatted}),
            ...(newPost.google_place_id && {google_place_id: newPost.google_place_id})
        };

        if (isEditMode && selectedPost && selectedPost._id) {
            const result = await updatePost(username || '', selectedPost._id.toString(), postToSubmit);
            if (result === true) {
                const updatedPosts = posts.map(post =>
                    post._id === selectedPost._id ? {...post, ...postToSubmit} : post
                );
                setPosts(updatedPosts);
                toast.success('Post updated successfully!');
            } else {
                toast.error('Failed to update the post.');
            }
        } else {
            const result = await addPost(username || '', postToSubmit);
            if (result === true) {
                setPosts([...posts, {...postToSubmit, _id: new Date().getTime().toString()}]);
                toast.success('Post added successfully!');
            } else {
                toast.error('Failed to add the post.');
            }
        }

        setUploading(false);
        setIsEditMode(false);
        setSheetOpen(false);
        setIsOverlayOpen(false); // Close the overlay

        setSelectedPost(null);
        setFileToUpload(null); // Clear the temporary file state
        setPreviewUrl(null); // Clear the preview URL
    };

    const handleEditPost = (post: Post) => {
        if (isViewing) return;
        setIsViewing(false);
        // setSheetOpen(false);
        // setIsOverlayOpen(false);

        setNewPost(post);
        setSelectedPost(post); // Set the post to edit
        setIsEditMode(true);
        setSheetOpen(true);
        setIsOverlayOpen(true);
        setFormattedAddressPreview(post.location_english_formatted || null);
    };

    const handleAddPost = () => {
        setIsViewing(false);
        // setSheetOpen(false);
        // setIsOverlayOpen(false)

        setIsEditMode(true);
        setSelectedPost(null); // Clear the post
        setNewPost({
            _id: 0,
            images: [],
            title: '',
            description: '',
            location: '',
            reactionCount: 0,
            ratings: {
                dishRating: undefined,
                wouldEatAgain: undefined
            },
            location_english_formatted: '', // Reset location_english_formatted
            google_place_id: ''
        });
        setSheetOpen(true); // Open modal for adding a new post
        setIsOverlayOpen(true);
        setFormattedAddressPreview(null); // Reset the formatted address preview

    };

    const handleViewPost = (post: Post) => {
        if (isEditMode) return;


        console.log('dish_click');
        ReactGA.set({page: `/${username}`});
        ReactGA.event({
            category: 'dish_interaction',
            action: 'dish_click',
            label: post.title, // Post title
            value: Number(post._id), // Post ID as a value
            nonInteraction: false, // This is an interaction event
            transport: 'xhr' // Default transport method
        });

        // posthog.capture('dish_click', { name: post.title, page: `/${username}`, postId: post._id});
        posthog.capture('dish_click', {
            category: 'dish_interaction',
            action: 'click',
            title: post.title, // The title of the dish/post
            postId: post._id,  // Post ID
            path: window.location.pathname, // Captures the current path (/username)
            username: username
        });

        // Track with Plausible
        if (typeof plausible !== "undefined") {
            plausible('Dish Click', {
                props: {
                    path: window.location.pathname,
                    dish_id: post._id.toString(),
                    dish: post.title,
                    creator: username ? username : ''
                }
            });
        }

        setIsEditMode(false);
        // setSheetOpen(false);
        // setIsOverlayOpen(false)

        setSelectedPost(post);
        setIsViewing(true);
        setSheetOpen(true);
        setIsOverlayOpen(true)
    };

    const closeModal = () => {
        setFileToUpload(null);
        setPreviewUrl(null);
        setSheetOpen(false);
        setIsEditMode(false);
        setIsViewing(false);
        setSelectedPost(null);
        setIsOverlayOpen(false);
        setProfileEditOpen(false);
        setFormattedAddressPreview(null);
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0]; // Use the first file for now
            setFileToUpload(file); // Store the file in the temporary state
            const preview = URL.createObjectURL(file); // Generate the preview URL
            setPreviewUrl(preview); // Store the preview URL
            setNewPost(prevPost => ({
                ...prevPost,
                images: [preview], // Temporary URL for preview
            }));
        }
    };

    //google places things below
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || GOOGLE_MAPS_API_KEY, // Your Google Maps API Key
        libraries,
        // language:"en",
        // region: "US"
    });

    const onLoad = (autoC: google.maps.places.Autocomplete) => {
        console.log('onLoad');
        setAutocomplete(autoC);
    };

    const onPlaceChanged = async () => {
        console.log('Place changed:', autocomplete);
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            console.log("place", place);
            if (place && place.address_components) {
                const locationDetails = {
                    place_id: place.place_id || '',
                    name: place.name || '',
                    street: place.address_components.find(component => component.types.includes('route'))?.long_name || '',
                    city: place.address_components.find(component => component.types.includes('locality'))?.long_name || '',
                    country: place.address_components.find(component => component.types.includes('country'))?.long_name || '',
                };
                console.log('Selected place details:', locationDetails);
                let formattedAddress = null;

                try {
                    setIsFormattingAddress(true); // Set loading to true
                    let toBeFormattedAddress = "name: " + locationDetails.name + ", address: " + place.formatted_address;
                    console.log(toBeFormattedAddress);

                    // Call the getFormattedAddress endpoint to format the address
                    formattedAddress = await getFormattedAddress(toBeFormattedAddress);

                    if (formattedAddress === 'error') {
                        console.error('Failed to format the address.');
                        formattedAddress = null; // Set to null if there's an error
                    }
                } catch (error) {
                    console.error('Error formatting address:', error);
                    formattedAddress = null; // Set to null if there's an error
                } finally {
                    setIsFormattingAddress(false); // Set loading to false once completed
                }

                setNewPost({
                    ...newPost,
                    location: `${locationDetails.name}, ${locationDetails.street}, ${locationDetails.city}, ${locationDetails.country}`,
                    location_english_formatted: formattedAddress || `${locationDetails.name}, ${locationDetails.city}, ${locationDetails.country}`,
                    google_place_id: locationDetails.place_id,
                });

                if (formattedAddress && formattedAddress !== 'error') {
                    setFormattedAddressPreview(formattedAddress);
                }

            }
        } else {
            console.log('Autocomplete is not loaded.');
            toast.error('Autocomplete is not loaded.');
        }
    };

    const onUnmount = () => {
        console.log('Autocomplete component unmounted');
        setAutocomplete(null);

        const pacContainers = document.querySelectorAll('.pac-container');
        pacContainers.forEach(container => container!.parentNode!.removeChild(container));
    };


    //profile edit stuff

    // Profile Edit Submission (Handles Profile Image + Other Details)
    const handleProfileEditSubmit = async () => {
        if (!profileEditInfo) return;
        setUploading(true);

        let imageUrl = profileEditInfo.profileImage; // Keep existing image if not updated

        if (currentUser) {
            const email = currentUser.email;

            if (email) {
                // Handle profile picture upload if file selected
                console.log(fileToUpload);
                if (fileToUpload) {
                    try {
                        const creatorId = currentUser.uid; // Assuming creator ID is available
                        const uploadResult = await setProfilePicture(fileToUpload, creatorId);

                        if (uploadResult === 'invalid-file' || uploadResult === 'upload-error') {
                            toast.error('Profile picture upload failed');
                            return; // Stop submission if image upload fails
                        }

                        imageUrl = uploadResult; // Set new image URL
                        console.log(imageUrl);
                    } catch (error) {
                        toast.error('Profile picture upload failed');
                        setUploading(false);
                        return;
                    } finally {

                    }
                }

                // Proceed with updating the profile
                try {
                    const updateData = {
                        name: profileEditInfo.name,
                        bio: profileEditInfo.bio,
                        postsTitle: profileEditInfo.postsTitle,
                        profileImage: imageUrl // Use new or existing image URL
                    };

                    const result = await updateCreator(email!, updateData);
                    if (result === true) {
                        toast.success('Profile updated successfully');
                        setProfileInfo(updateData); // Update the displayed profile info after successful submission
                        setProfileEditOpen(false); // Close the modal
                    } else {
                        toast.error('Failed to update profile');
                    }
                } catch (error) {
                    toast.error('Failed to update profile');
                } finally {
                    setUploading(false);
                }

            }
        }

        setUploading(false);
    };

    // Handle profile image change for preview
    const handleProfileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0]; // Use the first file for now
            setFileToUpload(file); // Store the file in the temporary state
            const preview = URL.createObjectURL(file); // Generate the preview URL
            setPreviewUrl(preview); // Store the preview URL
        }
    };

    const getRelativeTime = (dateString: string | undefined) => {
        if (!dateString) return null;
        const date = new Date(dateString);
        return formatDistanceToNow(date, {addSuffix: true});  // Example: "3 days ago"
    };

    const handleReactionClick = async (postId: string | number) => {
        // Create a unique key for localStorage
        const localStorageKey = `${creatorId}_${postId}_liked`;

        // Check if the user has liked the post (true means liked, false means not liked)
        const isLiked = localStorage.getItem(localStorageKey) === 'true';
        localStorage.setItem(localStorageKey, (!isLiked).toString()); // Toggle the boolean value

        // Determine if we are incrementing or decrementing
        const increment = !isLiked; // If not liked, we want to increment; if liked, we decrement


        if (!isLiked) {
            // Add animation class for this postId
            setAnimationClasses((prev) => ({
                ...prev,
                [postId]: 'fill-animation',
            }));

            // Remove animation class after animation duration
            setTimeout(() => {
                setAnimationClasses((prev) => ({
                    ...prev,
                    [postId]: '', // Reset the animation class
                }));
            }, 600); // 600ms to match the animation duration
        }

        // Update the post state with the new reaction count and toggle the like status
        setPosts(posts.map(post => {
            if (post._id !== postId) return post;

            // If reactionCount is undefined, default it to 0
            const currentCount = post.reactionCount || 0;

            // Ensure the reactionCount does not go below 0
            const newReactionCount = isLiked
                ? Math.max(currentCount - 1, 0) // Ensure it doesn't decrement below 0
                : currentCount + 1;

            return {
                ...post,
                reactionCount: newReactionCount
            };
        }));

        // Send the reaction update to the backend
        const result = await updateReactionCount(username!, postId.toString(), increment);

        if (result === true) {
            console.log('updated reaction count');
        } else {
            localStorage.setItem(localStorageKey, (isLiked).toString()); // Toggle the boolean value back in case it fails.
            console.error('Error updating reaction count in the backend');
            // Optionally, revert the optimistic UI update if backend fails
            setPosts(posts.map(post => {
                if (post._id !== postId) return post;

                // Revert the reaction count to its previous value
                const currentCount = post.reactionCount || 0;
                const revertedReactionCount = isLiked
                    ? currentCount + 1 // Undo decrement
                    : Math.max(currentCount - 1, 0); // Undo increment but ensure no negative values

                return {
                    ...post,
                    reactionCount: revertedReactionCount
                };
            }));
        }
    };


    const handleGoogleMapsClick = (post: Post) => {
        if (typeof plausible !== "undefined") {
            plausible('Google Maps Click', {
                props: {
                    path: window.location.pathname,
                    dish_id: post._id?.toString(),
                    dish: post.title,
                    creator: username ? username : '',
                    restaurant: post.location ? post.location : ''
                }
            });
        }
    };

    return (
        <>

            {/* Dynamic SEO metadata */}
            <HelmetProvider>
                {/*<head>*/}
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription}/>
            </HelmetProvider>
            {/*</head>*/}

            <div className="profile-container">
                {/* Profile Header */}
                {profileInfo && (
                    <div className="profile-header">
                        <div className="header-top">
                            <Link to="/">
                                <img src={soltLogo} alt="Solt logo" className="solt-logo"/>
                            </Link>

                            {/* Icon for toggling the dropdown, aligned to the right */}
                            {isOwnProfile &&
                                <div className="profile-settings-icon" onClick={() => {
                                    setShowDropdown((prevState) => !prevState);
                                }}>
                                    <img src={settingsIcon} alt={'settings icon solt profile'}/>
                                </div>
                            }

                            {/* Dropdown for Log Out */}
                            {isOwnProfile && showDropdown && (
                                <div className={`dropdown ${showDropdown ? 'dropdown-active' : ''}`} ref={dropdownRef}>
                                    <div className="dropdown-option" onClick={handleLogout}>
                                        Log Out
                                    </div>
                                </div>
                            )}

                        </div>
                        <div className="profile-header-content">
                            <div className="profile-left">
                                <img
                                    src={profileInfo.profileImage} // Use preview if available
                                    alt={profileInfo.name}
                                    className="profile-image"
                                    onClick={() => {
                                        setShowImageModal(true)
                                    }} // Toggle log-out on profile click
                                />
                                <div className="profile-info">
                                    <h1>{profileInfo.name}</h1>
                                    <p className="profile-username">@{username}</p>
                                </div>
                            </div>


                            {/* Modal for enlarging the image */}
                            {showImageModal && (
                                <motion.div
                                    className="image-modal-overlay"
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    exit={{opacity: 0}}
                                    onClick={() => {
                                        setShowImageModal(false)
                                    }} // Close the modal when clicking the overlay
                                >
                                    <motion.div
                                        className="image-modal-content"
                                        initial={{scale: 0.8}}
                                        animate={{scale: 1}}
                                        exit={{scale: 0.8}}
                                    >
                                        <img
                                            src={profileInfo.profileImage}
                                            alt={profileInfo.name}
                                            className="profile-image-large" // Add a class for styling
                                        />
                                    </motion.div>
                                </motion.div>
                            )}

                            <div className="profile-stats">
                                <div className="stat-item">
                                    <span className="stat-count">{posts?.length}</span>
                                    <span className="stat-label">
                                      {posts?.length === 1 ? 'dish' : 'dishes'}
                                    </span>
                                </div>
                                <div className="stat-item">
                                      <span className="stat-count">
                                        {Array.from(new Set(posts.filter(post => post.location).map(post => post.location))).length}
                                      </span>
                                    <span className="stat-label">
                                        {Array.from(new Set(posts.filter(post => post.location).map(post => post.location))).length === 1 ? 'location' : 'locations'}
                                      </span>
                                </div>

                            </div>
                        </div>
                    </div>

                )}
                {profileInfo && <p className="profile-bio">{profileInfo.bio}</p>}

                <div className={"bar"}/>

                {/* Post title section */}
                {profileInfo && <h2 className="post-title">{profileInfo.postsTitle}</h2>}


                {/* Loading and error states */}
                {loading && <div className="loading-message"><p>Loading posts...</p></div>}
                {error && <div className="error-message"><p>{error}</p></div>}

                {/* Admin controls for adding a new dish */}
                {isAuthenticated && isOwnProfile && (
                    <div className="admin-controls">
                        <button onClick={() => setProfileEditOpen(true)}>Edit profile</button>
                        <button onClick={handleAddPost}>Add new dish</button>
                    </div>
                )}

                {/* Post Masonry Grid */}
                {!loading && posts.length > 0 ? (
                    <ResponsiveMasonry columnsCountBreakPoints={{1: 1, 350: 2, 900: 3}}>
                        <Masonry gutter="15px">
                            {posts.map((post) => (
                                <div key={post._id} className="post-card">
                                    <div className="profile-image-container">
                                        <img
                                            src={post.images[0]}
                                            alt={post.title}
                                            className="post-image"
                                            onClick={() => !isEditMode && handleViewPost(post)} // Only the image opens the modal
                                        />

                                        {/* Single Reaction positioned bottom-right */}
                                        <div className="single-reaction-overlay">
                                            <div
                                                className="reaction-item"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevents the image click event
                                                    handleReactionClick(post._id); // Handle reaction click separately
                                                }}
                                            >
                                                <img
                                                    src={localStorage.getItem(`${creatorId}_${post._id}_liked`) === 'true' ? likedReactionIcon : normalReactionIcon}
                                                    alt="Reaction Icon"
                                                    className={`reaction-icon ${animationClasses[post._id] || ''}`} // Use animation state instead of post object
                                                />
                                                <span
                                                    className="reaction-number">{post.reactionCount || 0}</span> {/* Fallback to 0 if missing */}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="post-info">
                                        {/*<h3>{post.title}</h3>*/}
                                        <div className="post-info-header">
                                            <h3
                                                className="post-title clickable"
                                                onClick={() => handleViewPost(post)}
                                            >
                                                {post.title}
                                            </h3>
                                            {post.ratings?.dishRating && (
                                                <div className="post-rating">
                                                    <img src={starIcon} alt="Star Icon"
                                                         className="review-star-icon"/> {/* Replace with actual star icon path */}
                                                    <span>{Number(post.ratings.dishRating || 0).toFixed(2)}</span>
                                                </div>
                                            )}
                                        </div>
                                        {/*{post.location && <p>{post.location}</p>}*/}

                                        {(post.location_english_formatted || post.location) && (
                                            <div className="profile-card-location-container">
                                                <img src={restaurantPlaceIcon} alt="Location Icon"
                                                     className="profile-card-location-icon"/>
                                                <p className="profile-location-text">
                                                    {post.location_english_formatted
                                                        ? post.location_english_formatted
                                                        : `${extractLocationInfo(post.location!).restaurant}, ${extractLocationInfo(post.location!).city}, ${extractLocationInfo(post.location!).country}`}
                                                </p>
                                            </div>
                                        )}

                                        {post.created && <span>{getRelativeTime(post.created)}</span>}
                                        {isAuthenticated && isOwnProfile && (
                                            <button className="edit-btn" onClick={(e) => {
                                                e.stopPropagation(); // Prevent clicking on the button from triggering the modal
                                                handleEditPost(post);
                                            }}>Edit</button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                ) : (
                    !loading && <div className="no-posts-message"><p>No posts available.</p></div>
                )}


                {/* Modal sheet for editing profile */}
                <AnimatePresence>
                    {isProfileEditOpen && (
                        <motion.div
                            className="overlay"
                            initial={{opacity: 0, y: "100%"}}
                            animate={{opacity: 1, y: "0%"}}
                            exit={{opacity: 0, y: "100%"}}
                            transition={{duration: 0.2, ease: "easeInOut"}}
                            // drag="y"
                            // dragConstraints={{ top: 0, bottom: 10 }}
                            // onDragEnd={handleDragEnd}
                        >
                            {/*<Sheet*/}
                            {/*    isOpen={isProfileEditOpen}*/}
                            {/*    onClose={() => setProfileEditOpen(false)}*/}
                            {/*    snapPoints={[0.7]}*/}
                            {/*    tweenConfig={{ease: 'easeInOut', duration: 0.3}}*/}
                            {/*    style={{zIndex: 100}}*/}
                            {/*>*/}
                            {/*    <Sheet.Container>*/}
                            {/*        <Sheet.Header/>*/}
                            {/*        <Sheet.Content>*/}
                            <div className="overlay-content">

                                <div className="bottom-sheet-content">
                                    {/* Profile picture upload */}
                                    <div className="image-previews">
                                        <img
                                            src={previewUrl || profileEditInfo?.profileImage} // Use preview if available
                                            alt="Profile Preview"
                                            className="image-preview"
                                        />
                                    </div>

                                    <label htmlFor="profile-image">Profile Picture</label>
                                    <input
                                        id="profile-image"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleProfileImageChange}
                                    />

                                    {/* Profile Name */}
                                    <label htmlFor="profile-name">Name</label>
                                    <input
                                        id="profile-name"
                                        type="text"
                                        value={profileEditInfo?.name || ''}
                                        onChange={(e) => setProfileEditInfo({...profileEditInfo, name: e.target.value})}
                                        placeholder="Enter your name"
                                    />

                                    {/* Profile Bio */}
                                    <label htmlFor="profile-bio">Bio</label>
                                    <textarea
                                        id="profile-bio"
                                        value={profileEditInfo?.bio || ''}
                                        onChange={(e) => setProfileEditInfo({...profileEditInfo, bio: e.target.value})}
                                        placeholder="Enter your bio"
                                    />

                                    {/* Posts Title */}
                                    <label htmlFor="posts-title">Posts Title</label>
                                    <input
                                        id="posts-title"
                                        type="text"
                                        value={profileEditInfo?.postsTitle || ''}
                                        onChange={(e) => setProfileEditInfo({
                                            ...profileEditInfo,
                                            postsTitle: e.target.value
                                        })}
                                        placeholder="Enter your posts title"
                                    />

                                    {/* Submit button */}
                                    <button onClick={handleProfileEditSubmit} className="form-submit-btn">
                                        {uploading ? <ClipLoader color={'#fff'} size={20}/> : 'Update'}
                                    </button>

                                    <button onClick={closeModal} className="form-cancel-btn">Cancel</button>

                                </div>
                            </div>

                        </motion.div>
                    )}
                </AnimatePresence>
                {/*        </Sheet.Content>*/}
                {/*    </Sheet.Container>*/}
                {/*    <Sheet.Backdrop/>*/}
                {/*</Sheet>*/}


                {/* overlay sheet for adding/editing a post */}
                <AnimatePresence>
                    {isEditMode && (
                        <motion.div
                            className="overlay"
                            initial={{opacity: 0, y: "100%"}}
                            animate={{opacity: 1, y: "0%"}}
                            exit={{opacity: 0, y: "100%"}}
                            transition={{duration: 0.2, ease: "easeInOut"}}
                            // drag="y"
                            // dragConstraints={{ top: 0, bottom: 10 }}
                            // onDragEnd={handleDragEnd}
                        >
                            <div className="overlay-content">
                                <div className="bottom-sheet-content">


                                    {/* Image preview */}
                                    {previewUrl ? (
                                        <div className="image-previews">
                                            <img src={previewUrl} alt="Preview" className="image-preview"/>
                                        </div>
                                    ) : (newPost.images[0] && (
                                            <div className="image-previews">
                                                <img src={newPost.images[0]} alt="Preview"
                                                     className="image-preview"/>
                                            </div>
                                        )
                                    )}

                                    {/* Image upload */}
                                    <label htmlFor="image">A photo with the dish</label>
                                    <input
                                        id="image"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />

                                    {/*<h2 className="category-title">Basic Details</h2>*/}
                                    {/* Post title */}
                                    <label htmlFor="title">Dish name</label>
                                    <input
                                        id="title"
                                        type="text"
                                        value={newPost.title}
                                        onChange={e => setNewPost({...newPost, title: e.target.value})}
                                        placeholder="Pizza Margherita"
                                    />

                                    {/* Description with "(optional)" hint */}
                                    <label className="description-title">
                                        Description <span className="description-optional">(optional)</span>
                                    </label>
                                    <textarea
                                        id="description"
                                        value={newPost.description}
                                        onChange={(e) => {
                                            setNewPost({...newPost, description: e.target.value});
                                            e.target.style.height = "auto"; // Reset height to allow shrinking
                                            e.target.style.height = `${e.target.scrollHeight}px`; // Set height to fit content
                                        }}
                                        placeholder="Say something about the dish, how is it, what it contains, what makes it special, etc..."

                                        className="auto-resize-textarea"
                                    />

                                    <h2 className="rating-section-header">🍽️&nbsp;Where did you eat this?</h2>
                                    {/* Autocomplete for restaurant location */}
                                    <label htmlFor="location" className='section-title'>Restaurant location</label>
                                    {isSheetOpen && isEditMode && isLoaded && (
                                        <Autocomplete
                                            onLoad={onLoad}
                                            onPlaceChanged={onPlaceChanged}
                                            options={{
                                                types: ['establishment'],
                                                fields: ['formatted_address', 'address_components', 'name', 'place_id']
                                            }}

                                            onUnmount={onUnmount}
                                        >
                                            <div className="autocomplete-container">
                                                <input
                                                    id="location"
                                                    type="text"
                                                    placeholder="Search for restaurant"
                                                    className="form-input"
                                                    value={newPost.location}
                                                    onChange={e => setNewPost({
                                                        ...newPost,
                                                        location: e.target.value
                                                    })}
                                                />
                                            </div>
                                        </Autocomplete>
                                    )}

                                    {/* Display formatted address preview */}
                                    {isFormattingAddress ? (
                                        <div className="loading-container">
                                            <ClipLoader color={'#000'} size={20}/> {/* Loading icon */}
                                        </div>
                                    ) : (
                                        formattedAddressPreview && (
                                            <p className="formatted-address-preview">Will be shown
                                                as: {formattedAddressPreview}</p>
                                        )
                                    )}

                                    {/*/!* Post description *!/*/}
                                    {/*<label htmlFor="description">Description</label>*/}
                                    {/*<textarea*/}
                                    {/*    id="description"*/}
                                    {/*    value={newPost.description}*/}
                                    {/*    onChange={e => setNewPost({...newPost, description: e.target.value})}*/}
                                    {/*    placeholder="Say something about the dish, how is it, what it contains, what makes it special..."*/}
                                    {/*/>*/}

                                    <h2 className="rating-section-header">⭐&nbsp;Rate it</h2>
                                    {/* Taste Rating */}
                                    <label htmlFor="dishRating" className='section-title'>How was the dish?</label>
                                    <div className="star-rating-container">
                                        {[1, 2, 3, 4, 5].map((rating) => (
                                            <span
                                                key={rating}
                                                className={`star-icon ${newPost.ratings?.dishRating! >= rating ? 'selected' : ''}`}
                                                onClick={() => setNewPost({
                                                    ...newPost,
                                                    ratings: {
                                                        ...newPost.ratings,
                                                        dishRating: rating
                                                    }
                                                })}
                                            >
                                                ★
                                            </span>
                                        ))}
                                    </div>


                                    {/* Would you eat it again? */}
                                    <label htmlFor="wouldEatAgain" className='section-title'>Would you eat it
                                        again?</label>
                                    <div className="eat-again-container">
                                    <button
                                            type="button"
                                            className={`eat-again-button ${newPost.ratings?.wouldEatAgain === false ? 'selected' : ''}`}
                                            onClick={() =>
                                                setNewPost({
                                                    ...newPost,
                                                    ratings: {
                                                        ...newPost.ratings,
                                                        wouldEatAgain: false
                                                    }
                                                })
                                            }
                                            data-choice="no"
                                        >
                                            {/*<span role="img" aria-label="No">👎</span>*/}
                                            <p>No</p>
                                        </button>
                                        <button
                                            type="button"
                                            className={`eat-again-button ${newPost.ratings?.wouldEatAgain === true ? 'selected' : ''}`}
                                            onClick={() =>
                                                setNewPost({
                                                    ...newPost,
                                                    ratings: {
                                                        ...newPost.ratings,
                                                        wouldEatAgain: true
                                                    }
                                                })
                                            }
                                            data-choice="yes"
                                        >
                                            {/*<span role="img" aria-label="Yes">👍</span>*/}
                                            <p>Yes</p>
                                        </button>
                                    </div>


                                    {/* Submit button */}
                                    <button onClick={handlePostSubmit} className="form-submit-btn">
                                        {uploading ? <ClipLoader color={'#fff'} size={20}/> : 'Submit'}
                                    </button>

                                    <button onClick={closeModal} className="form-cancel-btn">Cancel</button>

                                </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>


                <BottomModal isOpen={isViewing} onClose={closeModal}>
                    {selectedPost && (
                        <>
                            <div className="profile-overlay-content">

                                <div className="modal-bar"></div>

                                <div className="profile-fixed-image-box">
                                    <img
                                        src={selectedPost.images[0]}
                                        alt={selectedPost.title}
                                        className="profile-fixed-image"
                                    />
                                </div>

                                {/*/!* Dish Title *!/*/}
                                {/*<div className="title-info">*/}
                                {/*    <h3>{selectedPost.title}</h3>*/}
                                {/*</div>*/}

                                {/* Container for title and rating */}
                                <div className="overlay-title-rating-container">
                                    <h3 className="overlay-post-title">{selectedPost.title}</h3>
                                    {selectedPost.ratings?.dishRating && (
                                        <div className="overlay-post-rating">
                                            <img src={starIcon} alt="Star Icon" className="overlay-review-star-icon" />
                                            <span>{Number(selectedPost.ratings.dishRating).toFixed(2)}</span>
                                        </div>
                                    )}
                                </div>

                                {selectedPost.created && (
                                    <span className="timestamp-info">
                                                    Added {getRelativeTime(selectedPost.created)}
                                                </span>
                                )}

                                <div className="bar"></div>

                                <div className="info-card">
                                    <h4>Where to eat:</h4>
                                    <div className="location-container">
                                        <p className="location-text">{selectedPost.location_english_formatted || selectedPost.location}</p>
                                        <a
                                            href={
                                                selectedPost.google_place_id
                                                    ? `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(selectedPost.location || "Place Name")}&query_place_id=${selectedPost.google_place_id}`
                                                    : `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(selectedPost.location || "")}`
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="google-maps-icon"
                                            onClick={() => handleGoogleMapsClick(selectedPost)} // Track Google Maps Click
                                        >
                                            <img src={redirectIcon} alt="Google Maps"/>
                                        </a>
                                    </div>
                                </div>

                                {selectedPost.description && <div className="bar"></div>}

                                {selectedPost.description && (
                                    <div className="info-card">
                                        <h4>Description:</h4>
                                        <p className="description-text">{selectedPost.description}</p>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </BottomModal>

                {/* Toast notification */}
                <ToastContainer
                    autoClose={1000}
                    theme="colored"
                    draggable
                    hideProgressBar
                    closeButton={false}
                    newestOnTop
                />
            </div>

            {/* Footer */}

            <div className="profile-footer">
                <div className={"bar"}/>
                {/*<a href="https://solt.app" target="_blank" rel="noopener noreferrer">*/}
                {/*    <img className="footer-logo" src={soltLogo} alt="Solt Logo"/>*/}
                {/*</a>*/}
                {/*<p>Powered by <a href="https://solt.app" target="_blank" rel="noopener noreferrer">Solt</a>.</p>*/}
                <button className="footer-button"
                        onClick={() => window.open('https://solt.app/creators', '_blank')}>Become a foodie creator on
                    Solt
                </button>

            </div>

        </>
    );
};

export default Profile;

//authprovider.tsx

import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signInWithRedirect, getRedirectResult, signOut, User } from 'firebase/auth';
import { auth } from '../firebase';

interface AuthContextProps {
    currentUser: User | null;
    signUpWithGoogle: (isMobile: boolean) => Promise<void>;
    logOut: () => Promise<void>;
    getCurrentUser: () => User | null;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);

    const getCurrentUser = (): User | null => {
        return auth.currentUser;
    };

    const signUpWithGoogle = async (isMobile: boolean) => {
        const provider = new GoogleAuthProvider();
        if (isMobile) {
            // Mobile: Use redirect flow
            await signInWithRedirect(auth, provider);
        } else {
            // Desktop: Use popup flow
            await signInWithPopup(auth, provider);
            auth.currentUser?.reload(); // Force a reload of the current user

            // Ensure onAuthStateChanged fires and updates currentUser
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    setCurrentUser(user); // Update with the new user
                }
            });
        }
    };

    const logOut = async () => {
        await signOut(auth);
        setCurrentUser(null);

        console.log('Logged out', currentUser, auth.currentUser);
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setLoading(false);
        });
        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        signUpWithGoogle,
        logOut,
        getCurrentUser,
    };

    return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};

// src/helpers/helpers.tsx

/**
 * Utility function to shuffle an array randomly using Fisher-Yates (Knuth) shuffle algorithm.
 *
 * @param array - The array to be shuffled.
 * @returns The shuffled array.
 */
export const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

/**
 * Custom sorting function to shuffle posts and ensure no adjacent posts from the same creator.
 *
 * @param posts - The array of posts to be sorted.
 * @returns The sorted array of posts.
 */
export const customSortPosts = (posts: any[]) => {
    let shuffledPosts = shuffleArray([...posts]); // Make a copy of posts and shuffle them

    // Ensure that no two consecutive posts have the same creator
    for (let i = 1; i < shuffledPosts.length; i++) {
        if (shuffledPosts[i].creator.username === shuffledPosts[i - 1].creator.username) {
            // Find a post from a different creator to swap with
            let swapIndex = i + 1;
            while (swapIndex < shuffledPosts.length && shuffledPosts[swapIndex].creator.username === shuffledPosts[i].creator.username) {
                swapIndex++;
            }

            // If we found a valid post to swap, swap them
            if (swapIndex < shuffledPosts.length) {
                [shuffledPosts[i], shuffledPosts[swapIndex]] = [shuffledPosts[swapIndex], shuffledPosts[i]];
            } else {
                // If no other post was found (all remaining posts are from the same creator), leave as is
                break;
            }
        }
    }

    return shuffledPosts;
};


/**
 * Extracts the restaurant name, city name, and country from a location string.
 *
 * @param location - The location string, typically in the format "Restaurant, Address, City, Country".
 * @returns An object with restaurant, city, and country.
 */
export const extractLocationInfo = (location: string) => {
    const parts = location.split(',').map(part => part.trim());

    if (parts.length < 2) {
        return { restaurant: location, city: '', country: '' }; // Fallback if not enough parts
    }

    return {
        restaurant: parts[0],            // First part as the restaurant name
        city: parts[parts.length - 2],    // Penultimate part as the city name
        country: parts[parts.length - 1]  // Last part as the country
    };
};
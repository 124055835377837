import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './FloatingModal.css';
import closeIcon from '../assets/close-icon.png';

interface FloatingModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const FloatingModal: React.FC<FloatingModalProps> = ({ isOpen, onClose }) => {
    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="floating-modal-content"
                    initial={{height: 0, opacity: 0}}
                    animate={{height: 'auto', opacity: 1}}
                    exit={{height: 0, opacity: 0}}
                    transition={{duration: 0.3, ease: 'easeInOut'}}
                >
                    {/* Modal Header with Title and Close Icon */}
                    <div className="modal-header">
                        <h2 className="modal-title">The Place for Foodies</h2>
                        <button className="modal-close-button" onClick={onClose}>
                            <img src={closeIcon} alt="Close" className="modal-close-icon" />
                        </button>
                    </div>

                    {/* Intro Section */}
                    <section className="modal-section">
                        <h3>🍽️ What is Solt?</h3>
                        <p>
                            Solt is the place to discover, rate, and share top dishes from local restaurants, recommended by fellow food lovers.
                        </p>
                    </section>

                    {/*/!* Current Screen Explanation *!/*/}
                    {/*<section className="modal-section">*/}
                    {/*    <h3>🔍 Explore Dishes</h3>*/}
                    {/*    <p>*/}
                    {/*        Browse top-rated dishes recommended by food lovers. Click on any dish to see details like location, description, and reactions from other users.*/}
                    {/*    </p>*/}
                    {/*</section>*/}

                    {/* How to Use Solt */}
                    <section className="modal-section">
                        <h3>🍔 How to Use Solt</h3>
                        <ul>
                            <li><strong>Discover:</strong> Find meal inspiration from a curated list of unique dishes.</li>
                            <li><strong>Rate & React:</strong> Share your thoughts with likes and reactions to help others find the best eats.</li>
                            <li><strong>Share Your Finds:</strong> Become a creator to add and recommend your favorite dishes to the community.</li>
                        </ul>
                    </section>

                    <section className="modal-section">
                        <h3>🧑‍🍳 Join as Foodie Creator on your Niche</h3>
                        <p>
                            Share your unique tastes on Solt as the "Pizza Pro" "Vegan Guru" or "Protein Enthusiast"
                            Your profile and preferences shape search results, helping users find recommendations that match their lifestyle and cravings.
                        </p>
                    </section>

                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default FloatingModal;

import React, { useState, useEffect, useRef } from 'react';
import './BottomModal.css';

interface BottomModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const BottomModal: React.FC<BottomModalProps> = ({ isOpen, onClose, children }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const [dragging, setDragging] = useState(false);
    const [startY, setStartY] = useState<number | null>(null);
    const [translateY, setTranslateY] = useState(0);
    const [closing, setClosing] = useState(false);
    const [animateOpen, setAnimateOpen] = useState(true); // Initially false

    useEffect(() => {
        if (isOpen) {
            setTranslateY(0);
            setClosing(false);
            setAnimateOpen(false);  // Start with animateOpen false

            // Apply opening class after a short delay to ensure smooth animation
            setTimeout(() => {
                setAnimateOpen(true);
            }, 50);
        } else {
            setAnimateOpen(false);  // Reset opening animation state when closed
        }
    }, [isOpen]);


    // Close modal when clicking outside on desktop with animation
    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
                handleCloseWithAnimation();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isOpen]);

    const handleTouchStart = (e: React.TouchEvent) => {
        setDragging(true);
        setStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        if (dragging && startY !== null) {
            const offsetY = e.touches[0].clientY - startY;
            if (offsetY > 0) {
                setTranslateY(offsetY);
            }
        }
    };

    const handleTouchEnd = () => {
        setDragging(false);
        const windowHeight = window.innerHeight;

        if (translateY > 150) {
            // If dragged more than a small amount, continue sliding down
            setTranslateY(windowHeight); // Continue sliding off-screen
            setTimeout(() => {
                setClosing(true);
                setTimeout(onClose, 300); // Close after 300ms (duration of slide)
            }, 0);
        } else {
            // Reset the modal to its original position
            setTranslateY(0);
        }
    };

    const handleCloseWithAnimation = () => {
        setClosing(true); // Trigger closing animation
        setTimeout(() => {
            onClose(); // Trigger the actual closing after the animation
        }, 150); // Match the animation duration (e.g., 0.8s for transform)
    };

    return (
        <>
            {isOpen && (
                <div className={`modal-overlay ${closing ? 'closing' : ''}`} onClick={onClose}>
                    <div
                        className={`modal-content ${closing ? 'closing' : ''} ${animateOpen ? 'opening' : ''}`}
                        ref={modalRef}
                        onClick={(e) => e.stopPropagation()} // Prevent modal from closing when content is clicked
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                    >
                        <div className="modal-body">
                            {children} {/* Content inside the scrollable modal body */}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default BottomModal;

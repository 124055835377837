import React from 'react';

const Terms: React.FC = () => {
    return (
        <>


            <title>Solt - The place for foodies and food reviewers</title>
            <meta name="description"
                  content="Solt is a simple link in bio tool for food reviewers to create and share their food content and grow their foodies community."/>

            <div style={{padding: '2rem'}}>
                <h1>Terms of Service</h1>
                <p>This is where your terms of service content will go.</p>
                {/* Add your terms of service content here */}
            </div>

        </>

    )
        ;
};

export default Terms;
